@import url('https://fonts.googleapis.com/css2?family=Fonarto&display=swap');

.mobileWarningContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #add8e6, #87ceeb);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 1000;
}

.mobileWarningMessage {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Fonarto', sans-serif;
    margin: auto 0;
    position: relative;
    z-index: 1001;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
}

.footerContent {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 14px;
}