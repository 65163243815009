@keyframes fluidBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@font-face {
  font-family: "fonarto";
  src: url("../../assets/fonts/fonarto.regular.ttf") format("truetype");
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    -45deg,
    #52d7ee,
    #5188b9,
    #23a6d5,
    #6795fa,
    #247ddd,
    #24d9f1
  );
  background-size: 800% 800%;
  animation: fluidBackground 205s ease infinite;
  height: 90px;
  padding: 20px;
  color: white;
  transition: transform 0.5s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: "fonarto", sans-serif;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .header {
    height: 140px;
    width: 503px;
  }
}
@media (max-width: 768px) {
  .logo {
    height: 70px !important;
    margin-left: -130px !important;
    margin-top: 35px !important;
    transition: filter 0.3s ease;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1));
    z-index: 10;
}
}
@media (max-width: 768px) {
  .h1 {
    margin-top: 80px;
    font-size: 7.5rem !important;
    font-weight: 300;
    position: absolute;
    display: inline-block;
    text-shadow: 0 0 15px rgba(0, 255, 255, 1.8), 2px 4px 6px rgba(0, 0, 0, 0.5);
  }
}

.header::after,
.header::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 45px;
  pointer-events: none;
}

.header::after {
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #52a0ee 100%
  );
}

.header::before {
  top: 0;
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #52a8ee 100%);
}

@keyframes logoSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo.spin {
  animation: logoSpin 0.75s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.logo {
  height: 85px;
  margin-left: -152.5px;
  margin-top: 25px;
  transition: filter 0.3s ease;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1));
  z-index: 10;
}

.logo.glow {
  filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.5))
    drop-shadow(0 0 5px rgba(255, 255, 255, 0.5))
    drop-shadow(0 0 10px rgba(255, 255, 255, 0.7));
}

.h1 {
  margin-top: 80px;
  font-size: 8.5rem;
  font-weight: 300;
  padding-left: 0px;
  position: absolute;
  display: inline-block;
  text-shadow: 0 0 15px rgba(0, 255, 255, 1.8), 2px 4px 6px rgba(0, 0, 0, 0.5);
}
