.switch-container {
    width: 60px;
    height: 25px;
    background-color: #ccc;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .switch-container.toggled {
    background-color: #4CAF50;
  }
  
  .switch-circle {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s;
  }
  
  .switch-container.toggled .switch-circle {
    transform: translateX(40px);
  }