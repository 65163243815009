.App {
  text-align: center;
}

@keyframes appBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

html, body {
  overflow-x: hidden;
  max-width: 100vw;
  padding-bottom: -50px;
}

html {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  max-width: 100vw;
}

body {
  overflow: auto;
  max-width: 100vw;
  height: 100vh;
  background: linear-gradient(-90deg, #a6d0ffdd, #d6ecff, #9ddbffdd, #fafeff);
  background-size: 800% 800%;
  animation: appBackground 60s ease infinite;
  color: #24CAE3;
  font-family: sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 200;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.codeSnippetContainer {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  gap: 20px !important;
}

.phoneFrame {
  width: 305px;
  height: 640px;
  border-radius: 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  
  /* Chrome-like frame styles */
  border: 2px solid #ccc;
  box-shadow:
    inset 0 0 0 5px #f2f2f2,
    inset 0 0 0 6px #ccc,
    0 4px 8px rgba(0, 0, 0, 0.2);
}

.phoneFrame::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 8px;
  border-radius: 4px;
  background-color: #ccc;
  background-image: linear-gradient(to right, #6bafe0, #b2b2b2, #8bc6ed);
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3);
}

.phoneFrame::after::before {
  content: '';
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.componentScaler {
  transform: scale(0.8);
}

.detailsModalView {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 300px;
  height: auto;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}